<template>
  <div class="main_content program_emp_search align-items-center" style="min-height: 80vh">
    
    <div class="row justify-content-between mb_40">
      <div class="col-12 col-md-12 col-xl-7 col-xxl-5">
        <div class="heading">
          <h2 class="Employee_head heading-dark">Reports</h2>
          <h3 class="Program_dashboard_head">
            <!-- <router-link
              class="text-darks hov_router"
              :to="{ name: 'client_manager' }"
            >
              PBE Management Dashboard
            </router-link>
            / -->
            <router-link class="text-darks hov_router" :to="{ name: 'client_manager' }">
              Program Management
            </router-link>
            /
            <span class="breadcrumb-item active" style="color: #ff9500 !important;"> {{ clientName }}</span>
            <!-- <router-link
              class="text-darks hov_router"
              :to="{ name: 'client_manager' }"
            >
              Reports
            </router-link> -->
          </h3>
        </div>
      </div>
    </div>
    <div class="table_card hght_tble">
      <div class="row dataTables_wrapper no-footer" id="datatable_wrapper">
        <div class=" col-xl-12 col-lg-12 col-md-12 col-xs-12 text-center text-lg-start ">
          <h4 class="font_bold font_size-20 text-darks">
            Evaluator QC Reports
          </h4>
        </div>
        <div class=" col-xl-4 col-lg-3 col-md-12 col-xs-12 text-md-center text-lg-start align-self-center my-2 ">
          <div
            class=" dataTables_length float-center float-md-none float-lg-start text-sm-start text-md-center text-lg-start "
            id="datatable_length">
            <label>Show
              <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                @change="getReportsBysearch">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </select>
              entries</label>
          </div>
        </div>
        <div class="col-xl-8 col-lg-9 col-md-12 col-xs-12">
          <div class=" d-flex justify-content-lg-end justify-content-center align-items-center flex-wrap mb-4 mb-lg-4 ">
            <div id="datatable_filter" class="dataTables_filter my-2">
              <h1 v-if="reportCheckbox.length" class="updatestatus update_rgt mb-0 text-center text-md-center">
                Update Status
              </h1>
              <label v-else class="mb-0">
                <input type="search" class="" placeholder="Search" aria-controls="datatable"
                  v-model="listConfig.search_string" v-on:keyup.enter="getReportsBysearch" />
                <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getReportsBysearch"
                  class="search_icon slty_up">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#7d8d9e" class="bi bi-search"
                    viewBox="0 0 16 16">
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </a>
                <a href="javascript:void(0)" v-if="listConfig.search_string"
                  @click=" listConfig.search_string = null; getReportsBysearch();" class="crossicon x_icon">
                  X
                </a>
              </label>
            </div>
            <div class="visited_pages status_box marg_left adj_status my-2">
              <div class="heading">
                <div class=" region_select status_box new_status status_select form-group status_drop mb-0 ">
                  <v-select v-if="reportCheckbox.length" class="vs-drop-cust" @input="updateBulkStatus($event)"
                    :reduce="(update_report_status) => update_report_status.id" :options="update_report_status"
                    label="name" placeholder="All Status" name="Status" v-model="listConfig.search_status"
                    v-validate="'required'" />
                  <v-select v-else class="vs-drop-cust" @input="updateBulkStatus($event)"
                    :reduce="(report_status) => report_status.id" :options="report_status" label="name"
                    placeholder="All Status" name="Status" v-model="listConfig.search_status" v-validate="'required'" />
                </div>
              </div>
            </div>
            <div v-if="reportCheckbox.length && oldSelectedCheckbox != 'Hidden'"
              class="region_select status_box marg_left mb-1">
              <a href="javascript:" class="comman_icons" @click="updateBulkStatus('Hidden')">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.975" viewBox="0 0 17 16.975">
                  <path id="Path_107" data-name="Path 107"
                    d="M11.431,15.437a7.874,7.874,0,0,1-8.974-5.364A7.836,7.836,0,0,1,3.685,7.693m4.6.714a2.358,2.358,0,0,1,3.335,3.335M8.289,8.406l3.334,3.334M8.291,8.407,5.705,5.821m5.92,5.92,2.586,2.586M2.884,3,5.705,5.821m0,0a7.862,7.862,0,0,1,11.752,4.252,7.879,7.879,0,0,1-3.247,4.253m0,0,2.821,2.821"
                    transform="translate(-1.457 -1.586)" fill="none" stroke="#a9a9a9" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" />
                </svg>
                <span class="ml_10"><b>Hide</b></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="myTab2Content" class="tab-content">
            <div id="percent" role="tabpanel" aria-labelledby="#percent" class="tab-pane fade active show">
              <div class="all_user">
                <div class="table-responsive table_audit_log report_table">
                  <table class="table table-bordered table dataTable no-footer">
                    <thead>
                      <tr>
                        <th style="min-width: 10px" class="sort sml_check">
                          <div class="form_check_box d-flex align-items-center mt-1">
                            <input type="checkbox" v-model="selectAll" aria-label="..." id="flexCheckDefault"
                              class="form-check-input ms-0" @change="checkSelected($event)" />
                            <label for="flexCheckDefault" class=""> </label>
                          </div>
                        </th>
                        <th align="center" valign="top" class="sort divi_DOE">
                          <SortIcons :lable="'Date of Eval'" :listConfig="listConfig"
                            :sortBy="'alchemer_summary_reports.assessment_date'" @sorting="sorting($event)" />
                        </th>
                        <th align="center" valign="top" class="sort equal_col">
                          <SortIcons :lable="'Client Name'" :listConfig="listConfig" :sortBy="'clients.company_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th align="center" valign="top" class="sort equal_col">
                          <SortIcons :lable="'First Name'" :listConfig="listConfig" :sortBy="'users.first_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th align="center" valign="top" class="sort equal_col">
                          <SortIcons :lable="'Last Name'" :listConfig="listConfig" :sortBy="'users.last_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th align="center" valign="top" class="sort col_to_email">
                          <SortIcons :lable="'Email Address'" :listConfig="listConfig"
                            :sortBy="'alchemer_summary_reports.user_email'" @sorting="sorting($event)" />
                        </th>
                        <th align="center" valign="top" class="sort coloum_of_evaluaotr">
                          <SortIcons :lable="'Evaluator'" :listConfig="listConfig"
                            :sortBy="'alchemer_summary_reports.evaluator'" @sorting="sorting($event)" />
                        </th>
                        <th align="center" valign="top" class="sort col_to_ststus move_icon">
                          <SortIcons :lable="'Status'" :listConfig="listConfig"
                            :sortBy="'alchemer_summary_reports.status'" @sorting="sorting($event)" />
                          <svg style="margin-left: 10px"
                            v-tooltip="'Re-Review, In Review, Declined, Finalized, Evaluator Reviewed, Evaluator QC Reviewed, Hidden & Pending Client Approval'"
                            xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                            viewBox="0 0 14 18.346">
                            <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                            <path data-name="Path 9427"
                              d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                              transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                          </svg>
                        </th>
                        <th align="center" style="text-align: center" scope="col" class="col_to_action">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(repo, indx) in reports_data" :key="indx">
                        <td style="min-width: 10px">
                          <div class="form_check_box d-flex align-items-center">
                            <input type="checkbox" v-model="reportCheckbox" :value="repo.id" aria-label="..."
                              :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                              @change="checkSelected($event)" />
                            <label :for="'flexCheckDefault' + indx" class=""></label>
                          </div>
                        </td>
                        <td>
                          <p>{{ repo.date }}</p>
                        </td>
                        <td>
                          <p>{{ repo.client }}</p>
                        </td>
                        <td>
                          <p>{{ repo.firstName }}</p>
                        </td>
                        <td>
                          <p>{{ repo.lastName }}</p>
                        </td>
                        <td>
                          <p>{{ repo.email }}</p>
                        </td>
                        <td>
                          <p>
                            {{ repo.evaluator }}
                            <span v-if="repo.evaluatorExists != ''"><br /><small>(User does not exists in our
                                system)</small></span>
                          </p>
                        </td>
                        <!-- <td>
                          <p v-if="repo.status == 'Re-Review'" class="blue_review" >
                            {{ repo.status }}
                          </p>
                          <p v-if=" repo.status == 'In Review' || repo.status == 'Hidden' " class="gray_review" >
                            {{ repo.status }}
                          </p>
                          <p v-if=" repo.status == 'Evaluator Reviewed' || repo.status == 'Evaluator QC Reviewed' " class="orange_review" >
                            {{ repo.status }}
                          </p>
                          <p v-if="repo.status == 'Finalized'" class="light_blue_review" >
                            {{ repo.status }}
                          </p>
                          <p v-if="repo.status == 'Declined'" class="pink_review" >
                            {{ repo.status }}
                          </p>
                          <p v-if="repo.status == 'Pending Client Approval'" class="gold_review" >
                            {{ repo.status }}
                          </p>
                        </td> -->
                        <td>
                          <p v-bind:class="switchStatusColor(repo.status)">
                            {{ repo.status }}
                          </p>
                        </td>
                        <td style="text-align: center">
                          <div class="edit_btn">
                            <p>
                              <a href="javascript:" @click="showReportDataSet(repo.dataset)" aria-current="page"
                                title="Data Set"
                                class=" view-dshboad-btn router-link-exact-active router-link-active ms-2 "
                                data-bs-toggle="modal" data-bs-target="#dataset">
                                <img class="view-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                  alt="img" />
                              </a>
                              <router-link :to="{ name: 'view_edit_client_report', params: { url: repo.u_id }, }"
                                target="_blank" title="Edit Survey Report">
                                <img class="edit-icon" :src="require('@/assets/images/dashboard/Edit_btn_table.svg')"
                                  alt="img" />
                              </router-link>
                              <a href="javascript:" @click="getSingleReportHistory(repo.id)" aria-current="page"
                                data-bs-toggle="modal" data-bs-target="#history"
                                class=" router-link-exact-active router-link-active view-dshboad-btn " title="History">
                                <img :src="require('@/assets/images/dashboard/timer.png')" alt="img" class="edit-icon" />
                              </a>
                              <a href="javascript:" aria-current="page" @click="allComments(repo)" data-bs-toggle="modal"
                                data-bs-target="#commentModal" title="Add Comment">
                                <img :src="require('@/assets/images/dashboard/note.svg')" alt="img" class="edit-icon">
                              </a>
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="data_length == 0">
                        <td colspan="9" class="text-center">
                          No Records found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="float-lg-start text-sm-center">{{ positionText }}</p>
                <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                  :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DatasetModal v-bind:dataSet="dataSet" v-bind:isEmptyDataSet="isEmptyDataSet" ref="datasetModal"></DatasetModal>
    <HistoryModal v-bind:history="reportsHistory" v-bind:isShowHistory="showHistory" ref="historyModal"></HistoryModal>
    <div class="modal fade box_up commentModal" id="commentModal" tabindex="-1" aria-labelledby="downloadLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h3 class="heading_1">Eval Request Comments</h3>
            </h5>
            <button type="button" class="btn-close commentModalBtn" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body details_eval">
            <div v-if="allCommentArr.length">
              <div class="row mb-3">
                <div class="table table-responsive cmt_eva_table cust_scroll">
                  <table class="table" width="100%">
                    <tbody>
                      <tr>
                        <th style="width: 30%">Name</th>
                        <th style="width: 30%">Email</th>
                        <th style="width: 40%">Comment</th>
                      </tr>
                      <tr v-for="(comment, indx) in allCommentArr" :key="indx">
                        <td valign="middle" style="width: 30%">
                          {{ comment.user_name }}
                        </td>
                        <td valign="middle" style="width: 30%">
                          {{ comment.user_email }}
                        </td>
                        <td valign="middle" style="width: 40%">
                          {{ comment.comments }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="!allCommentArr.length" class="row">
              <div class="col-xs-12 col-md-12">
                <h2 class="text-center no_data mb-5">There are no comments</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <div class="mb-3 form-group maxheight">
                  <div class="form-group">
                    <textarea class="form-control" aria-describedby="emailHelp" placeholder="Write your comment here"
                      v-model="add_comment" v-validate="'required'" v-on:keyup="countdown" maxlength="1000" />
                    <label class="text-end w-100">{{ remainingCount }} characters remaining</label>
                  </div>
                </div>
                <div v-if="add_comment.length" class="text-center mt_30">
                  <button @click="addComment" class="btn btn_primary mr_10" type="button">
                    Add Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal box_up fade" id="dataset" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header download-btn-right">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">Data Set</h2>
            </h5>
            <div class="down_cross">
               <a href="javascript:" class="ml_12 xl_download" v-if="this.dataSet" @click="exportDataset()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" >
                <g data-name="Group 12689">
                  <path data-name="Path 114" d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4" transform="translate(-3 -3)" style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                </g>
              </svg>
              Download
              </a>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetDataSet" ></button>
            </div>
          </div>
          <Spinner ref="Spinner"></Spinner>
          <div class="modal-body details_eval">
            <div class="row justify-content-center">
              <div class="col-xs-12 col-md-12 main-timeline cust_scroll">
                <div class="table table-responsive">
                  <table class="table historyTable" width="100%" align="center">
                    <tr>
                      <th style="width: 10%">ID</th>
                      <th style="width: 25%">QUESTION</th>
                      <th style="width: 65%">ANSWER</th>
                    </tr>
                    <tr v-for="(dataValue, dataKey) in dataSet" :key="dataKey">
                      <td v-html="dataKey"></td>
                      <td v-html="dataValue.question"></td>
                      <td v-html="dataValue.answer ? dataValue.answer : 'Not Answered'"></td>
                    </tr>
                    <tr v-if="!this.dataSet">
                      <td colspan="6" class="text-center">No Records found</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="modal box_up fade" id="history" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">History</h2>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
          </div>
          <Spinner ref="Spinner"></Spinner>
          <div class="modal-body details_eval">
            <div class="row justify-content-center">
              <div class="col-xs-10 col-md-10 main-timeline cust_scroll">
                <div v-show="showHistory">
                  <div class="timeline" v-if="reportsHistory.length">
                    <div :class=" indx % 2 == 0 ? 'container_timeline left' : 'container_timeline right' " v-for="(history, indx) in reportsHistory" :key="indx" >
                      <div class="date date_set">
                        {{ history.history_date }}
                      </div>
                      <div class="content">
                        <h1>{{ history.history_key }}</h1>
                        <h2>{{ history.history_value }}</h2>
                        <h6>By: {{ history.user_name }}</h6>
                        <h6 v-if="!history.user_exist">
                          (User does not exists in our system)
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div v-if="!reportsHistory.length" class="row">
                    <div class="col-xs-12 col-md-12">
                      <h2 class="text-center no_data mb-5">
                        There is no history
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from "../../mixin/commonFunction";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import ConfirmDialogue from "@/components/ConfirmDialogue";
import DatasetModal from "@/components/DatasetModal";
import HistoryModal from "@/components/HistoryModal";
import commonService from "../../services/commonService";

export default {
  name: "employee_search",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    ConfirmDialogue,
    DatasetModal,
    HistoryModal
  },
  data() {
    return {
      show: false,
      users: [],
      reports_data: [],
      selected: true,
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "alchemer_summary_reports.assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
        search_status: null,
        regionBy: null,
        clientId: "",
      },
      data_length: 0,
      isDisabled: "",
      addPermission: false,
      deletePermission: false,
      delete_id: 0,
      delete_res: "",
      delete_reason: "",

      allCommentArr: [],
      add_comment: "",
      limitmaxCount: 1000,
      maxCount: 1000,
      remainingCount: 1000,
      hasError: false,
      commentArr: [],
      addCommentArr: {
        report_id: null,
        comments: null,
      },
      report_status: commonService.summaryReportStatus(),
      update_report_status: commonService.summaryReportUpdateStatus(),
      // report_status: [
      //   {
      //     id: null,
      //     name: "All Status",
      //   },
      //   {
      //     id: "Re-Review",
      //     name: "Re-Review",
      //   },
      //   {
      //     id: "In Review",
      //     name: "In Review",
      //   },
      //   {
      //     id: "Declined",
      //     name: "Declined",
      //   },
      //   {
      //     id: "Finalized",
      //     name: "Finalized",
      //   },
      //   {
      //     id: "Evaluator Reviewed",
      //     name: "Evaluator Reviewed",
      //   },
      //   {
      //     id: "Evaluator QC Reviewed",
      //     name: "Evaluator QC Reviewed",
      //   },
      //   {
      //     id: "Hidden",
      //     name: "Hidden",
      //   },
      //   {
      //     id: "Pending Client Approval",
      //     name: "Pending Client Approval",
      //   },
      // ],
      // update_report_status: [
      //   {
      //     id: "Re-Review",
      //     name: "Re-Review",
      //   },
      //   {
      //     id: "In Review",
      //     name: "In Review",
      //   },
      //   {
      //     id: "Declined",
      //     name: "Declined",
      //   },
      //   {
      //     id: "Finalized",
      //     name: "Finalized",
      //   },
      //   {
      //     id: "Evaluator Reviewed",
      //     name: "Evaluator Reviewed",
      //   },
      //   {
      //     id: "Evaluator QC Reviewed",
      //     name: "Evaluator QC Reviewed",
      //   },
      //   {
      //     id: "Pending Client Approval",
      //     name: "Pending Client Approval",
      //   },
      // ],

      clearButton: true,
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      isCheckAll: false,
      selectedUser: [],
      base_url: null,

      reportCheckbox: [],
      dataArr: [],
      reportCheckboxArr: {
        status: null,
        checkboxes: [],
      },
      editUrlArr: {
        survey_id: null,
        session_id: null,
      },
      oldSelectedCheckbox: null,
      showHistory: false,
      dataSet: [],
      isEmptyDataSet: true,
      reportsHistory: [],
      clientName: 'Reports',
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.reports_data
          ? this.reportCheckbox.length == this.reports_data.length
          : false;
      },
      set: function (value) {
        var reportCheckbox = [];
        if (value) {
          this.reports_data.forEach(function (data) {
            reportCheckbox.push(data.id);
          });
        }
        this.reportCheckbox = reportCheckbox;
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getReports();
    },
  },
  destroyed() {
    this.$parent.clientManagerDashboard = false;
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "alchemer_summary_reports.assessment_date",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
      regionBy: null,
      search_status: null,
      clientId: "",
    };
    this.getReports();
    if (this.$route.params.successMsg) {
      this.$toast.success(this.$route.params.successMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    if (this.$route.params.errorMsg) {
      this.$toast.error(this.$route.params.errorMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    this.$parent.clientManagerDashboard = true;
  },
  // beforeMount() {
  //   // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
  //   if (localStorage.getItem("userToken")) {
  //     window.addEventListener("scroll", this.scrollvue);
  //     // Store for check permission
  //     let res = this.$store.dispatch("auth/getAuthUser");
  //     res
  //       .then((value) => {
  //         let allPermission = value.user.roles.all_permission;
  //         if (allPermission.includes("users-edit")) {
  //           this.addPermission = true;
  //         }
  //         if (allPermission.includes("users-delete")) {
  //           this.deletePermission = true;
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     this.tokenExpired();
  //   }
  // },
  methods: {
    checkSelected(event) {
      if (event.target.checked) {
        this.listConfig.search_status = "";
      } else {
        if (this.reportCheckbox.length == 0) {
          this.listConfig.search_status = this.oldSelectedCheckbox;
        }
      }
    },
    allComments(review) {
      this.add_comment = "";
      this.remainingCount = 1000;
      this.allCommentArr = [];
      this.fetchAllComments(review);
    },
    fetchAllComments(review) {
      if (review.id) {
        this.commentArr = [];
        this.addCommentArr.report_id = review.id;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL + "/api/reports-review-all-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                this.allCommentArr = decodedJson.records;
              }
            })
            .catch(({ response }) => {
              if (response.status == 401) {
                _this.tokenExpired();
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    addComment() {
      if (this.add_comment.length) {
        this.commentArr = [];
        this.addCommentArr.comments = this.add_comment;
        this.commentArr.push(this.addCommentArr);

        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL + "/api/reports-review-add-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                _this.$toast.success(data.message, {
                  position: "top-right",
                });
                document.getElementsByClassName("commentModalBtn")[0].click();
              } else {
                _this.$toast.error(data.message, {
                  position: "top-right",
                });
              }
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            })
            .catch(({ response }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              if (response.status == 401) {
                _this.tokenExpired();
              } else {
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.add_comment.length;
      this.hasError = this.remainingCount < 0;
      if (this.hasError) {
        console.log("Haserror:", this.hasError);
        return;
      }
    },
    showReportDataSet(dataSet) {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
        if (dataSet != null) {
          var i = 1;
          for (const item in dataSet) {
            dataSet[item].id = i++;
            if (dataSet[item].options) {
              for (const item1 in dataSet[item].options) {
                if (dataSet[item].options[item1].answer) {
                  if (dataSet[item].answer)
                    dataSet[item].answer += ', ' + dataSet[item].options[item1].answer;
                  else
                    dataSet[item].answer = dataSet[item].options[item1].answer;
                }
              }
            }
            if (dataSet[item].subquestions) {
              for (const item2 in dataSet[item].subquestions) {
                if (dataSet[item].subquestions[item2].answer) {
                  if (dataSet[item].answer) {
                    dataSet[item].answer += ', ' + dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                  else {
                    dataSet[item].answer = dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                }
              }
            }
          }
          this.dataSet = dataSet;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
          this.isEmptyDataSet = Object.keys(dataSet).length === 0;
        } else {
          this.dataSet = [];
          this.isEmptyDataSet = true;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        }
      } else {
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        $('.modal-backdrop').remove()
        $(document.body).removeClass("modal-open");
        this.tokenExpired();
      }
    },
    showReportDataSetOld(dataSet) {
      this.dataSet = dataSet;
    },
    getSingleReportHistory(reportId) {
      if (reportId) {
        this.showHistory = false;
        var requestData = { reportId: reportId };
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/reports-all-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequest(requestData) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.reportsHistory = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              console.log(response);
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    openEditForm(survey_id, session_id) {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      this.editUrlArr.survey_id = survey_id;
      this.editUrlArr.session_id = session_id;
      var _this = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/get-report-edit-url",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: this.editUrlArr,
      };
      _this
        .axios(config)
        .then(({ data }) => {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (data.success) {
            var reportURL = data.edit_url;
            window.open(reportURL);
          }
        })
        .catch(({ response }) => {
          if (response.status == 401) {
            _this.$router.push({ name: "login" });
          }
        });
    },
    async updateBulkStatus($event) {
      if (this.reportCheckbox.length && $event != null) {
        const confirmUpdate = await this.$refs.confirmDialogue.show({
          title: "Update Status",
          message: "Are you sure you want to update status?",
          okButton: "Update",
        });
        if (confirmUpdate) {
          this.dataArr = [];
          this.reportCheckboxArr.status = $event;
          this.reportCheckboxArr.checkboxes = this.reportCheckbox;
          this.dataArr.push(this.reportCheckboxArr);
          if (localStorage.getItem("userToken")) {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            if (_this.$route.params.id != "" && _this.$route.params.id != undefined) {
              _this.listConfig.clientId = _this.$route.params.id;
            }
            let config = {
              method: "post",
              url: process.env.VUE_APP_API_URL + "/api/review-status-bulk-edit/" + _this.listConfig.clientId,
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
              },
              data: { request_data: this.encodeAPIRequest(this.dataArr) },
            };
            _this
              .axios(config)
              .then(({ data }) => {
                if (data.success) {
                  _this.$toast.success(data.message, {
                    position: "top-right",
                  });
                  // this.listConfig.report_status = "";
                  this.listConfig.search_status = null;
                  this.reportCheckbox = [];
                  this.getReportsBysearch();
                } else {
                  _this.$toast.error(data.message, {
                    position: "top-right",
                  });
                }
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              })
              .catch(({ response }) => {
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              });
          } else {
            this.tokenExpired();
          }
        }
      } else {
        this.reportCheckbox.length = 0;
        this.getReportsBysearch();
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getReports();
    },
    getReportsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getReports();
    },
    getReports() {
      var _this = this;
      _this.oldSelectedCheckbox = this.listConfig.search_status;
      if (_this.$route.params.id != "" && _this.$route.params.id != undefined) {
        _this.listConfig.clientId = _this.$route.params.id;
      }
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/client-manager-reports/",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : this.encodeAPIRequestWithImages(_this.listConfig)},
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          _this.clientName = decodedData.clientName;
          _this.reports_data = decodedData.reports;
          _this.data_length = decodedData.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          } else if (response.status == 403) {
            _this.$router.push({ name: "403" });
          } else if (response.status == 401) {
            _this.$router.push({ name: "login" });
          } else {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
          }
          if (response.data.type == false) {
            _this.tokenExpired();
          }
        });
    },
  },
};
</script>